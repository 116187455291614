import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { ConvoEntry } from '../models/ConvoEntry'
import { LanguageMap } from '../models/LanguageMap'
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Stack from 'react-bootstrap/Stack';
import Placeholder from 'react-bootstrap/Placeholder';
import * as Icon from 'react-bootstrap-icons';
import LocalizedStrings from 'react-localization';
import QRCode  from 'qrcode.react';
import { useParams } from 'react-router-dom'
import { useLocation } from "react-router-dom";

var ws: WebSocket;
let translationRecognizer: SpeechSDK.ConversationTranslator;
var isSpeaking;
var langMaps: LanguageMap[] = [new LanguageMap("en-US", "en-US"), new LanguageMap("zh-CN", "zh-Hans")];

let strings = new LocalizedStrings({
    en: {
        create: "Create",
        join: "Join",
        welcome: "Welcome To The Conversation",
        username: "Username",
        convoid: "Convo Id",
        speak: "Speak",
        hold: "Hold",
        chat: "Chat",
        submit: "Submit",
        english: "English",
        chinese: "Chinese"
    },
    cn: {
        create: "创造",
        join: "加入",
        welcome: "欢迎来到对话",
        username: "用户名",
        convoid: "对话编号",
        speak: "说",
        hold: "拿",
        chat: "聊天",
        submit: "提交",
        english: "英语",
        chinese: "中文"
    }
});

function Two() {

    const query = new URLSearchParams(useLocation().search);
    const convoId = query.get("convoId");
    var cvid:string = '';
    if(convoId != null)
        cvid = convoId;
    
        
    const nameVal = localStorage.getItem('name');
    var nameid:string = '';
    if(nameVal != null)
    nameid = nameVal;
    const [textToSendLan, setTextToSendLan] = React.useState('');
    const [isAudioEnabled, setIsAudioEnabled] = React.useState(true);
    const [isMicEnabled, setIsMicEnabled] = React.useState(false);

    const [textUser, setTextUser] = React.useState(nameid);
    const [langId, setLangId] = React.useState('1');
    const [convos, setConvos] = React.useState<ConvoEntry[]>([]);

    const [conversationId, setConversationId] = React.useState(cvid);
    const [convoStarted, setConvoStarted] = React.useState(false);
    const [showQR, setShowQR] = React.useState(false);
    const [showError, setShowError] = React.useState(false);

    


    const initSpeechCall = async (e, isJoin:Boolean) => {

        localStorage.setItem('name', textUser);
        isSpeaking = false;
        const speechTranslationConfig = SpeechSDK.SpeechTranslationConfig.fromSubscription('9edd7994a0ad441187c04cfcb9b86e88', 'eastus');
        speechTranslationConfig.speechRecognitionLanguage = langMaps[parseInt(langId) -1].textLanguage;

        const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
        translationRecognizer = new SpeechSDK.ConversationTranslator(audioConfig);
        if(!isJoin){
        // create a conversation
        var conversation = SpeechSDK.Conversation.createConversationAsync(speechTranslationConfig, function () {

            // success callback for create conversation
            conversation.startConversationAsync(
                // success callback for start conversation
                function (result) {
                    console.log("succuess:" + conversation.conversationId);
                    setConversationId(conversation.conversationId);
                    setConvoStarted(true);
                    console.log(langMaps[parseInt(langId)]);
                    // join the conversation as the host
                    handleJoinConversation(true, conversation, textUser, langMaps[parseInt(langId) -1].textLanguage,

                        // success callback for join conversation
                        function (result) {
                            console.log("Joined Ok");

                        },

                        // error callback for join conversation
                        function (error) {
                            // error on joining         
                            console.log("Error joining");
                            console.log(error);
                        });

                },

                // error callback for start conversation
                function (error) {
                    setShowError(true);
                    console.log("Error starting convo");
                    console.log(error);
                });

        },

            // error callback for create conversation
            function (error) {
                setShowError(true);
                console.log(error);
                /*phraseDiv.innerHTML += "\rError creating conversation: " + error;
                resetUI();*/
            });
        }
        else{
            
            handleJoinConversation(false, conversationId, textUser, langMaps[parseInt(langId)- 1].textLanguage,

            // success callback for join conversation
            function (result) {
                console.log("Joined Ok");
                setConvoStarted(true);

            },

            // error callback for join conversation
            function (error) {
                setShowError(true);
                // error on joining         
                console.log("Error joining");
                console.log(error);
            });
        }
        
    };
    const speakUbu = async (speakText, lang) => {
        var config = SpeechSDK.SpeechTranslationConfig.fromSubscription('9edd7994a0ad441187c04cfcb9b86e88', 'eastus');
        config.speechRecognitionLanguage = 'en-US';
        if(lang === "en-US")
        {
            config.speechSynthesisLanguage = "zh-Cn";
            config.speechSynthesisVoiceName = "zh-CN-XiaochenNeural";
        }
        else{
            config.speechSynthesisLanguage = "en-US";
        }
        var synthesizer = new SpeechSDK.SpeechSynthesizer(config);
        synthesizer.speakTextAsync(
          speakText,
          function (result) {
            window.console.log(result);
            synthesizer.close();
          });
      }

    function handleJoinConversation(isHost, conversation, nickname, language, cb, err) {

        var participants: SpeechSDK.IParticipant[] = [];

        // optionally, create the audio config
        var audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();


        // hook up the events

        // the conversation is cancelled
        translationRecognizer.canceled = (s, e) => {
            window.console.log(e);
            switch (e.errorCode) {
                case SpeechSDK.CancellationErrorCode.NoError:
                    // the user has been disconnected
                    /*phraseDiv.innerHTML += "\r" + e.errorDetails;*/
                    break;
                default:
                    /*phraseDiv.innerHTML += "\rCanceled due to error. " + e.errorCode + ": " + e.errorDetails;*/
                    break;
            }

            /*
            // if it was unexpected, make sure leave is called
            if (!isDisconnecting) {
              handleLeaveConversation();
            }*/
        };

        // info alert for the conversation about to expire
        translationRecognizer.conversationExpiration = (s, e) => {
            window.console.log(e);
            // phraseDiv.innerHTML += "\rConversation will expire in " + e.expirationTime + " minutes";
        };

        // info alert that participants have joined or left or a change has been made
        translationRecognizer.participantsChanged = (s, e) => {
            window.console.log(e);
            //phraseDiv.innerHTML += "\rThe following participant(s) have ";
            switch (e.reason) {
                case SpeechSDK.ParticipantChangedReason.JoinedConversation:
                    //phraseDiv.innerHTML += "joined";
                    for (var i = 0; i < e.participants.length; i++) {
                        participants.push(e.participants[i]);
                    }
                    break;

                case SpeechSDK.ParticipantChangedReason.LeftConversation:
                    //phraseDiv.innerHTML += "left";
                    break;

                case SpeechSDK.ParticipantChangedReason.Updated:
                    //phraseDiv.innerHTML += "been updated";
                    break;
            }
            //phraseDiv.innerHTML += ":";

            for (var i = 0; i < e.participants.length; i++) {
                //phraseDiv.innerHTML += "\t" + e.participants[i].displayName;
            }

        }

        // session has started
        translationRecognizer.sessionStarted = (s, e) => {
            window.console.log(e);

            //phraseDiv.innerHTML += "\rSession started: " + e.sessionId;
        };

        // session has stopped
        translationRecognizer.sessionStopped = (s, e) => {
            setShowError(true);
            window.console.log(e);
            //phraseDiv.innerHTML += "\rSession stopped: " + e.sessionId;
        };

        // instant message has been received
        translationRecognizer.textMessageReceived = (s, e) => {

            window.console.log("Message Receive");

            window.console.log(e);
            window.console.log(language);
            window.console.log(e.result.language);

            if (e.result.originalLang == "en-US") {
                var convoNew = new ConvoEntry(e.result.text, e.result.translations.get("zh-Hans"), e.result.originalLang, 1, new Date());
                //speakUbu(e.result.translations.get("zh-Hans"), "zh-CN");
                setConvos(prevConvos => [convoNew, ...prevConvos]);
            }
            else {
                var convoNew = new ConvoEntry(e.result.text, e.result.translations.get("en-US"), e.result.originalLang, 2, new Date());
                //speakUbu(e.result.translations.get("en-US"), "en-US");
                setConvos(prevConvos => [convoNew, ...prevConvos]);
            }

            //phraseDiv.innerHTML += "\rReceived an instant message from " + getParticipantName(e.result.participantId) + ": " + e.result.text;
            //phraseDiv.innerHTML += "\rTranslated into " + language + ": " + e.result.translations.get(language);
        };

        // a 'partial' message has been received
        translationRecognizer.transcribed = (s, e) => {
            window.console.log("Message transcribed");
            window.console.log(e);
            window.console.log("Message Receive");
            window.console.log(e);
            window.console.log(language);



            if (e.result.originalLang == "en-US") {
                var convoNew = new ConvoEntry(e.result.text, e.result.translations.get("zh-Hans"), e.result.originalLang, 1, new Date());
                //speakUbu(e.result.translations.get("zh-Hans"), "zh-CN");
                setConvos(prevConvos => [convoNew, ...prevConvos]);
            }
            else {
                var convoNew = new ConvoEntry(e.result.text, e.result.translations.get("en-US"), e.result.originalLang, 2, new Date());
                //speakUbu(e.result.translations.get("en-US"), "en-US");
                setConvos(prevConvos => [convoNew, ...prevConvos]);
            }

            //phraseDiv.innerHTML += "\rReceived a transcription from " + getParticipantName(e.result.participantId) + ": " + e.result.text;
            //phraseDiv.innerHTML += "\rTranslated into " + language + ": " + e.result.translations.get(language);
        };

        // a 'final' message has been received
        translationRecognizer.transcribing = (s, e) => {
            window.console.log(e);
        };

        try {
            if (isHost) {
                // join the conversation as the host
                translationRecognizer.joinConversationAsync(conversation, nickname, cb, err);
            } else {
                // join the conversation as a participant
                translationRecognizer.joinConversationAsync(conversation, nickname, language, cb, err);
            }
        } catch (e) {
            window.console.log(e);
            //phraseDiv.innerHTML += "\rUnexpected error joining: " + e;
            if (err) {
                //err(e);
            }
        }

        function getParticipantName(id) {
            for (var i = 0; i < participants.length; i++) {
                if (participants[i].id == id) {
                    return participants[i].displayName;
                }
            }
            return id;
        }
    }


    const sendMessage = async (e: any) => {
        e.preventDefault();

        console.log(textToSendLan);

        translationRecognizer.sendTextMessageAsync(textToSendLan, function () {
            console.log("send text message");
            //phraseDiv.innerHTML += "\rsend text message";
        }, function (error) {
            setShowError(true);
            console.log("error sending text message " + error);
            //phraseDiv.innerHTML += "\r" + error;
        });
    };


    const updateLanguage = async (value: any) => {
        console.log("value changed" + value);
        setLangId(value);
        if (value === '1') {

            strings.setLanguage('en');
            //this.setState({});
        }
        else{            
            strings.setLanguage('cn');

        }
    };
    const speakAudio = async (e: any) => {
        e.preventDefault();

        console.log(isSpeaking);
        if (!isSpeaking) {
            isSpeaking = true;
            translationRecognizer.startTranscribingAsync(function () {
                console.log("send start speak");
                setIsMicEnabled(true);

            }, function (error) {
                console.log("error speaking " + error);
                setIsMicEnabled(false);
            });
        }
        else {
            isSpeaking = false;
            translationRecognizer.stopTranscribingAsync(function () {
                console.log("send stop speak");
                setIsMicEnabled(false);
            }, function (error) {
                console.log("error stop speaking " + error);
                setIsMicEnabled(false);
            });
        }
    
        setIsAudioEnabled(!isSpeaking);
      };


    //}

    return (<Container className='disable-select'>
        <Row>
            <Col>
                <Container className="p-3 mb-4 bg-light rounded-3">
                    <h1 className="header">
                        {showError && <Icon.XOctagonFill fill='red'></Icon.XOctagonFill>} {strings.welcome}: <a href="#" onClick={e=>setShowQR(!showQR)}>{conversationId}</a> &nbsp;
                    </h1>
                    {showQR &&<QRCode value={"https://conversate.bryansbits.com/two?convoId=" + conversationId} />}
                </Container></Col>
        </Row>

        {!convoStarted &&
            <Row>
                <Col>
                    <Form>

                        <div key={`inline-radio`} className="mb-3">
                            <Form.Check
                                inline
                                label="English"
                                value="1"
                                name="group1"
                                type="radio"
                                checked={'1' === langId}
                                onChange={e => updateLanguage(e.currentTarget.value)}
                                id={`inline-radio-1`}
                            />
                            <Form.Check
                                inline
                                value="2"
                                checked={'2' === langId}
                                onChange={e => updateLanguage(e.currentTarget.value)}
                                label="中文"
                                name="group1"
                                type="radio"
                                id={`inline-radio-2`}
                            />
                        </div>
                    </Form>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder={strings.username}
                            aria-describedby="basic-addon2"  value={textUser} onChange={e => setTextUser(e.target.value)} 
                        />
                        <Button variant="outline-secondary" id="button-addon2" onClick={e => initSpeechCall(e, false)}>
                            {strings.create}
                        </Button>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder={strings.convoid}
                            aria-describedby="basic-addon2"  value={conversationId} onChange={e => setConversationId(e.target.value)} 
                        />
                        <Button variant="outline-secondary" id="button-addon2"  onClick={e => initSpeechCall(e, true)}>
                        {strings.join}
                        </Button>
                    </InputGroup>
                </Col>

            </Row>
        }
        {convoStarted &&
            <Row>
                <Col>

                    <div className='container'>
                        <div className='row'>
                            
                    <InputGroup className="mb-3">
                        <FormControl value={textToSendLan} onChange={e => setTextToSendLan(e.target.value)} placeholder={strings.chat} />
                        <Button variant="outline-secondary" id="button-addon2" onClick={e => sendMessage(e)}>
                            {strings.submit}
                        </Button>
                    </InputGroup>
                        </div>
                        <Button className="btn-lg w-75" variant="info" onMouseDown={e => speakAudio(e)} onMouseUp={e => speakAudio(e)} onTouchStart={e => speakAudio(e)} onTouchEnd={e => speakAudio(e)} disabled={!isAudioEnabled}>
                            &nbsp;&nbsp;&nbsp;{strings.speak} ({strings.hold})&nbsp;&nbsp;&nbsp;
                        </Button>&nbsp;&nbsp;&nbsp;

                        {isMicEnabled && <Icon.Mic color='red' size="2em"></Icon.Mic>}
                    </div>
                </Col>
            </Row>
        }

        {convoStarted &&
            <Row>
                <Col>
                    <Container>

                        {convos.map(row => {
                            return (
                                <Row className='mt-2' key={row.time.toString()} >
                                    <Col xs="2"><img src={(row.place == 1) ? '/img/icons8-usa-96.png' : '/img/icons8-china-96.png'} />{/* <Placeholder style={{ width: '50px', height: '50px', background: (row.place == 1) ? 'blue' : 'red' }} /> */}</Col>
                                    <Col xs="10">
                                        <div style={{ background: (row.place == 1) ? '#EBFCFC' : '#FCEBEB' }} className={(row.state === 1) ? "border-3 border p-2 border-success" : (row.state === 2) ? "border-3 border p-2 border-warning" : "border-3 border p-2"}>
                                            <Container>
                                                <Row>
                                                    <Col lg="10">

                                                        <strong className="primary-font">{(row.place == 1) ? strings.english : strings.chinese} - {row.language}</strong> <small className="pull-right text-muted">
                                                            <Icon.Clock></Icon.Clock>{Math.abs((new Date().getTime() - row.time.getTime()) / 1000)}</small>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {row.message}
                                                        <br />
                                                        <Icon.PlayBtn onClick={e=>speakUbu(row.translatemessage, row.language)}></Icon.PlayBtn> {row.translatemessage}
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>

                                    </Col>
                                </Row>
                            );
                        })}
                        {/* <Row className='mt-2'>
            <Col md="auto"><Placeholder style={{ width: '50px', height: '50px' }} /></Col>
            <Col>
              <div className="bg-light border">
                <div className="header">
                  <strong className="primary-font">Second Person</strong> <small className="pull-right text-muted">
                    <span className="glyphicon glyphicon-time"></span>12 mins ago</small>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curar bibe0m oe
                  dolor, quis ullamcorper ligula sodales.
                </p>
              </div></Col>
          </Row> */}

                    </Container>

                </Col>
            </Row>
        }
    </Container>);
};

export default Two;