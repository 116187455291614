import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

var ws :WebSocket;
let translationRecognizer: SpeechSDK.TranslationRecognizer;
var isSpeaking;

function MultiChat() {
    const [textToSendWS, setTextToSendWS] = React.useState('');
    const [textUser, setTextUser] = React.useState('');
    const initSpeechCall = async () => {
        isSpeaking = false;
        const speechTranslationConfig =SpeechSDK.SpeechTranslationConfig.fromSubscription('9edd7994a0ad441187c04cfcb9b86e88', 'eastus');
        speechTranslationConfig.speechRecognitionLanguage = "en-US";
        speechTranslationConfig.addTargetLanguage("de-DE");

        const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
        translationRecognizer = new SpeechSDK.TranslationRecognizer(speechTranslationConfig, audioConfig);
        //translationRecognizer.addTargetLanguage("de");


        console.log("enteredfunction");
        let authenticated = window.location.href.includes('?authenticated=true');
        // if (!authenticated) {
        //     // auth
        //     let login = document.querySelector("#login");
        //     let link = document.createElement('a');
        //     link.href = `${window.location.origin}/.auth/login/aad?post_login_redirect_url=/api/index?authenticated=true`;
        //     link.text = "login";
        //     if (login != null)
        //         login.appendChild(link);
        // }
        // else {
        // negotiate

        console.log("enteredfunction2s");
        let res = await fetch(`${window.location.origin}/api/GetName?id=` + textUser, {
            credentials: "include"
        });

        let url = await res.json();

        console.log(url);
        // connect
        ws = new WebSocket(url.url);
        ws.onopen = () => console.log('connected');
        ws.onmessage = event => {
            console.log("message rec");
            console.log(event);
            let m = document.createElement('p');
            m.innerText = event.data;

            let messages = document.querySelector('#messages') as HTMLDivElement;
            messages!.appendChild(m);
        };

        translationRecognizer.recognizing = (s, e) => {
            console.log(e);
            console.log(`TRANSLATING: Text=${e.result.text}`);
        };
        translationRecognizer.recognized = (s, e) => {
            if (e.result.reason == SpeechSDK.ResultReason.RecognizedSpeech) {
                console.log(`TRANSLATED: Text=${e.result.text}`);
            }
            else if (e.result.reason == SpeechSDK.ResultReason.NoMatch) {
                console.log("NOMATCH: Speech could not be translated.");
                console.log(e);
            }
        };
        translationRecognizer.canceled = (s, e) => {
            console.log(`CANCELED: Reason=${e.reason}`);
            if (e.reason == SpeechSDK.CancellationReason.Error) {
                console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                console.log("CANCELED: Did you update the subscription info?");
            }
            translationRecognizer.stopContinuousRecognitionAsync();
        };
        translationRecognizer.sessionStopped = (s, e) => {
            console.log("\n    Session stopped event.");
            translationRecognizer.stopContinuousRecognitionAsync();
        };
    };

      // shared function for a host or participant to connect a conversation translator to a conversation
  
  const speakUbu = async (speakText, lang) => {
    var config = SpeechSDK.SpeechTranslationConfig.fromSubscription('9edd7994a0ad441187c04cfcb9b86e88', 'eastus');
    config.speechRecognitionLanguage = 'en-US';
    if(lang === "en-US")
    {
      config.speechSynthesisLanguage = "en-US";
    }
    else{
      config.speechSynthesisLanguage = "zh-Cn";
      config.speechSynthesisVoiceName = "zh-CN-XiaochenNeural";
    }
    var synthesizer = new SpeechSDK.SpeechSynthesizer(config);
    synthesizer.speakTextAsync(
      speakText,
      function (result) {
        window.console.log(result);
        synthesizer.close();
      });
  }

  const sendMessage = async (e: any) => {
    e.preventDefault();

        console.log("message sending" + textToSendWS);
        ws.send(textToSendWS);
        setTextToSendWS('');
    };

    const speakAudio = async (e: any) => {
        e.preventDefault();
        console.log(isSpeaking);
        if (!isSpeaking) {
          isSpeaking = true;
          translationRecognizer.startContinuousRecognitionAsync(function () {
            console.log("send start speak");
        //     if (place == 1)
        //       setIsMic1Enabled(true);
        //     else
        //       setIsMic2Enabled(true);
    
        //   }, function (error) {
        //     console.log("error speaking " + error);
        //     if (place == 1)
        //       setIsMic1Enabled(false);
        //     else
        //       setIsMic2Enabled(false);
          });
    
        //   setIsEnAudioEnabled(place == 1);
        //   setIsCnAudioEnabled(place == 2);
        }
        else {
          isSpeaking = false;
          translationRecognizer.stopContinuousRecognitionAsync(function () {
            console.log("send stop speak");
        //     if (place == 1)
        //       setIsMic1Enabled(false);
        //     else
        //       setIsMic2Enabled(false);
        //   }, function (error) {
        //     console.log("error stop speaking " + error);
        //     if (place == 1)
        //       setIsMic1Enabled(false);
        //     else
        //       setIsMic2Enabled(false);
          });
        //   setIsEnAudioEnabled(true);
        //   setIsCnAudioEnabled(true);
        }
    };


        //}

    return (<div>
        <h1>Azure Web PubSub Serverless Chat App</h1><input id="usertext" placeholder="user..." value={textUser} onChange={e => setTextUser(e.target.value)}/> <button onClick={e=>initSpeechCall()}>start</button>
        <div id="login"></div>
        <p></p>
        <input id="message" placeholder="Type to chat..." value={textToSendWS} onChange={e => setTextToSendWS(e.target.value)}/> <button onClick={e=>sendMessage(e)}>send</button>
        <button onMouseDown={e => speakAudio(e)} onMouseUp={e => speakAudio(e)}>Talk</button>
        <div id="messages"></div>
    </div>);
  };
  
  export default MultiChat;