import React from 'react';

export class LanguageMap {

    //field 
    textLanguage: string;
    translateLanguage: string;
  
    //constructor 
    constructor(textLanguage: string, translateLanguage: string) {
      this.textLanguage = textLanguage;
      this.translateLanguage = translateLanguage;
  
    }
  
    //function 
    disp(): void {
      console.log(this)
    }
  }