import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Placeholder from 'react-bootstrap/Placeholder';
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import * as Icon from 'react-bootstrap-icons';
import {ConvoEntry} from '../models/ConvoEntry'
import { BrowserRouter, Routes, Route } from "react-router-dom";


let conversationTranslator1: SpeechSDK.ConversationTranslator;
let conversationTranslator2: SpeechSDK.ConversationTranslator;
var isSpeaking;
var lastSession = "";
var lastConvo: ConvoEntry;

function Home() {

  const [convos, setConvos] = React.useState<ConvoEntry[]>([]);

  // Language 1
  const [textToSendLan1, setTextToSendLan1] = React.useState('');
  const [isEnAudioEnabled, setIsEnAudioEnabled] = React.useState(true);
  const [isMic1Enabled, setIsMic1Enabled] = React.useState(false);

  // Language 2
  const [textToSendLan2, setTextToSendLan2] = React.useState('');
  const [isMic2Enabled, setIsMic2Enabled] = React.useState(false);
  const [isCnAudioEnabled, setIsCnAudioEnabled] = React.useState(true);


  const [conversationId, setConversationId] = React.useState('');
  const [convoStarted, setConvoStarted] = React.useState(false);


  var conversation;

  var displayName, languageCode;

  /*
    const getDataFromApi = async (e: any) => {
      e.preventDefault();
      const data = await fetch(`/api/GetName?name=${name}`);
      const json = await data.json();
  
      if (json.message) {
        //setMessage(json.message);
      }
    };*/

  const adjustRow = async (e: any, ce: ConvoEntry, command: number) => {
    console.log(convos);

    var tempConvos = [...convos];
    switch (command) {
      case 1:
        ce.state = 1;
        break;
      case 2:
        ce.state = 2;
        break;
      case 3:
        let isRemove = window.confirm("Delete?");
        if(isRemove)
          tempConvos.splice(tempConvos.indexOf(ce), 1);
        break;
    }
    setConvos(tempConvos);
  };

  const sendMessage = async (e: any, place: any) => {
    e.preventDefault();
    let conversationTranslator: SpeechSDK.ConversationTranslator;
    let textToSendLan;

    if (place === 1) {
      conversationTranslator = conversationTranslator1;
      textToSendLan = textToSendLan1;
    } else {
      conversationTranslator = conversationTranslator2;
      textToSendLan = textToSendLan2;
    }

    console.log(textToSendLan);
    
    conversationTranslator.sendTextMessageAsync(textToSendLan, function () {
      console.log("send text message");
      //phraseDiv.innerHTML += "\rsend text message";
    }, function (error) {
      console.log("error sending text message " + error);
      //phraseDiv.innerHTML += "\r" + error;
    });
  };
  // shared function for a host or participant to connect a conversation translator to a conversation
  function speakUbu(speakText, lang) {
    var config = SpeechSDK.SpeechTranslationConfig.fromSubscription('9edd7994a0ad441187c04cfcb9b86e88', 'eastus');
    config.speechRecognitionLanguage = 'en-US';
    if(lang === "en-US")
    {
      config.speechSynthesisLanguage = "en-US";
    }
    else{
      config.speechSynthesisLanguage = "zh-Cn";
      config.speechSynthesisVoiceName = "zh-CN-XiaochenNeural";
    }
    var synthesizer = new SpeechSDK.SpeechSynthesizer(config);
    synthesizer.speakTextAsync(
      speakText,
      function (result) {
        window.console.log(result);
        synthesizer.close();
      });
  }
  // shared function for a host or participant to connect a conversation translator to a conversation
  function handleJoinConversation(isHost, conversation, nickname, language, cb, err, place: number) {

    var participants: SpeechSDK.IParticipant[] = [];

    // optionally, create the audio config
    var audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();

    let conversationTranslator: SpeechSDK.ConversationTranslator;

    // create the conversation translator
    if (place == 1) {
      conversationTranslator1 = new SpeechSDK.ConversationTranslator(audioConfig);
      conversationTranslator = conversationTranslator1;
    }
    else {
      conversationTranslator2 = new SpeechSDK.ConversationTranslator(audioConfig);
      conversationTranslator = conversationTranslator2;
    }

    // hook up the events

    // the conversation is cancelled
    conversationTranslator.canceled = (s, e) => {
      window.console.log(e);
      switch (e.errorCode) {
        case SpeechSDK.CancellationErrorCode.NoError:
          // the user has been disconnected
          /*phraseDiv.innerHTML += "\r" + e.errorDetails;*/
          break;
        default:
          /*phraseDiv.innerHTML += "\rCanceled due to error. " + e.errorCode + ": " + e.errorDetails;*/
          break;
      }

      /*
      // if it was unexpected, make sure leave is called
      if (!isDisconnecting) {
        handleLeaveConversation();
      }*/
    };

    // info alert for the conversation about to expire
    conversationTranslator.conversationExpiration = (s, e) => {
      window.console.log(e);
      // phraseDiv.innerHTML += "\rConversation will expire in " + e.expirationTime + " minutes";
    };

    // info alert that participants have joined or left or a change has been made
    conversationTranslator.participantsChanged = (s, e) => {
      window.console.log(e);
      //phraseDiv.innerHTML += "\rThe following participant(s) have ";
      switch (e.reason) {
        case SpeechSDK.ParticipantChangedReason.JoinedConversation:
          //phraseDiv.innerHTML += "joined";
          for (var i = 0; i < e.participants.length; i++) {
            participants.push(e.participants[i]);
          }
          break;

        case SpeechSDK.ParticipantChangedReason.LeftConversation:
          //phraseDiv.innerHTML += "left";
          break;

        case SpeechSDK.ParticipantChangedReason.Updated:
          //phraseDiv.innerHTML += "been updated";
          break;
      }
      //phraseDiv.innerHTML += ":";

      for (var i = 0; i < e.participants.length; i++) {
        //phraseDiv.innerHTML += "\t" + e.participants[i].displayName;
      }

    }

    // session has started
    conversationTranslator.sessionStarted = (s, e) => {
      window.console.log(e);

      //phraseDiv.innerHTML += "\rSession started: " + e.sessionId;
    };

    // session has stopped
    conversationTranslator.sessionStopped = (s, e) => {
      window.console.log(e);
      //phraseDiv.innerHTML += "\rSession stopped: " + e.sessionId;
    };

    // instant message has been received
    conversationTranslator.textMessageReceived = (s, e) => {
      if (place == 1) {
        window.console.log("Message Receive");

        window.console.log(e);
        window.console.log(language);
        window.console.log(e.result.language);

        if (e.result.originalLang == language) {
          var convoNew = new ConvoEntry(e.result.text, e.result.translations.get("zh-Hans"), e.result.originalLang, place, new Date());
          speakUbu(e.result.translations.get("zh-Hans"), "zh-CN");
          setConvos(prevConvos => [convoNew, ...prevConvos]);
        }
        else {
          var convoNew = new ConvoEntry(e.result.text, e.result.translations.get("en-US"), e.result.originalLang, 2, new Date());
          speakUbu(e.result.translations.get("en-US"), "en-US");
          setConvos(prevConvos => [convoNew, ...prevConvos]);
        }
      }
      //phraseDiv.innerHTML += "\rReceived an instant message from " + getParticipantName(e.result.participantId) + ": " + e.result.text;
      //phraseDiv.innerHTML += "\rTranslated into " + language + ": " + e.result.translations.get(language);
    };

    // a 'partial' message has been received
    conversationTranslator.transcribed = (s, e) => {
      window.console.log("Message transcribed");
      window.console.log(e);
      if (place == 1) {
        window.console.log("Message Receive");
        window.console.log(e);
        window.console.log(language);
        window.console.log(convos);




        if (e.result.originalLang == language) {
          var convoNew = new ConvoEntry(e.result.text, e.result.translations.get("zh-Hans"), e.result.originalLang, place, new Date());
          speakUbu(e.result.translations.get("zh-Hans"), "zh-CN");
          setConvos(prevConvos => [convoNew, ...prevConvos]);
        }
        else {
          var convoNew = new ConvoEntry(e.result.text, e.result.translations.get("en-US"), e.result.originalLang, 2, new Date());
          speakUbu(e.result.translations.get("en-US"), "en-US");
          setConvos(prevConvos => [convoNew, ...prevConvos]);
        }
      }
      //phraseDiv.innerHTML += "\rReceived a transcription from " + getParticipantName(e.result.participantId) + ": " + e.result.text;
      //phraseDiv.innerHTML += "\rTranslated into " + language + ": " + e.result.translations.get(language);
    };

    // a 'final' message has been received
    conversationTranslator.transcribing = (s, e) => {
      window.console.log(e);
      //phraseDiv.innerHTML += "\rReceived a partial transcription from " + getParticipantName(e.result.participantId) + ": " + e.result.text;
      //phraseDiv.innerHTML += "\rTranslated into " + language + ": " + e.result.translations.get(language);
    };

    try {
      if (isHost) {
        // join the conversation as the host
        conversationTranslator.joinConversationAsync(conversation, nickname, cb, err);
      } else {
        // join the conversation as a participant
        conversationTranslator.joinConversationAsync(conversation, nickname, language, cb, err);
      }
    } catch (e) {
      window.console.log(e);
      //phraseDiv.innerHTML += "\rUnexpected error joining: " + e;
      if (err) {
        //err(e);
      }
    }

    function getParticipantName(id) {
      for (var i = 0; i < participants.length; i++) {
        if (participants[i].id == id) {
          return participants[i].displayName;
        }
      }
      return id;
    }
  }


  const speakAudio = async (e: any, place: number) => {
    e.preventDefault();

    var conversationTranslator: SpeechSDK.ConversationTranslator;
    if (place == 1) {
      conversationTranslator = conversationTranslator1;
    } else {
      conversationTranslator = conversationTranslator2;
    }
    console.log(isSpeaking);
    if (!isSpeaking) {
      isSpeaking = true;
      conversationTranslator.startTranscribingAsync(function () {
        console.log("send start speak");
        if (place == 1)
          setIsMic1Enabled(true);
        else
          setIsMic2Enabled(true);

      }, function (error) {
        console.log("error speaking " + error);
        if (place == 1)
          setIsMic1Enabled(false);
        else
          setIsMic2Enabled(false);
      });

      setIsEnAudioEnabled(place == 1);
      setIsCnAudioEnabled(place == 2);
    }
    else {
      isSpeaking = false;
      conversationTranslator.stopTranscribingAsync(function () {
        console.log("send stop speak");
        if (place == 1)
          setIsMic1Enabled(false);
        else
          setIsMic2Enabled(false);
      }, function (error) {
        console.log("error stop speaking " + error);
        if (place == 1)
          setIsMic1Enabled(false);
        else
          setIsMic2Enabled(false);
      });
      setIsEnAudioEnabled(true);
      setIsCnAudioEnabled(true);
    }
  };

  const initSpeechCall = async (e: any) => {
    e.preventDefault();
    isSpeaking = false;
    var config = SpeechSDK.SpeechTranslationConfig.fromSubscription('9edd7994a0ad441187c04cfcb9b86e88', 'eastus');
    config.speechRecognitionLanguage = 'en-US';
    
    

    // create a conversation
    conversation = SpeechSDK.Conversation.createConversationAsync(config, function () {

      // success callback for create conversation
      conversation.startConversationAsync(
        // success callback for start conversation
        function (result) {
          console.log("succuess:" + conversation.conversationId);
          setConversationId(conversation.conversationId);
          setConvoStarted(true);
          //conversationId.value = conversation.conversationId;
          // phraseDiv.innerHTML += "\rStarted conversation " + conversation.conversationId;
          //leaveConversationButton.disabled = false;

          // join the conversation as the host
          handleJoinConversation(true, conversation, "host", "en-US",

            // success callback for join conversation
            function (result) {
              // joined ok
              /*phraseDiv.innerHTML += "\rJoined conversation " + conversation.conversationId;
              sendMessageButton.disabled = false;
              startSpeakingButton.disabled = false;
              messageInputBox.disabled = false;*/

            },

            // error callback for join conversation
            function (error) {
              // error on joining
              console.log(error);
              /*phraseDiv.innerHTML += "\rError joining conversation: " + error;
              resetUI();*/
            }, 1);

          // join the conversation as the part
          handleJoinConversation(false, conversation.conversationId, "Second Speaker", "zh-CN",

            // success callback for join conversation
            function (result) {
              console.log("joined convo second");
              // joined ok
              /*phraseDiv.innerHTML += "\rJoined conversation " + conversation.conversationId;
              sendMessageButton.disabled = false;
              startSpeakingButton.disabled = false;
              messageInputBox.disabled = false;*/

            },

            // error callback for join conversation
            function (error) {
              // error on joining
              console.log(error);
              /*phraseDiv.innerHTML += "\rError joining conversation: " + error;
              resetUI();*/
            }, 2);
        },

        // error callback for start conversation
        function (error) {
          console.log(error);
          /*phraseDiv.innerHTML += "\rError starting conversation: " + error;
          resetUI();*/
        });

    },

      // error callback for create conversation
      function (error) {
        console.log(error);
        /*phraseDiv.innerHTML += "\rError creating conversation: " + error;
        resetUI();*/
      });
  };

  return (
    <Container className='disable-select'>
      <Row>
        <Col>
          <Container className="p-3 mb-4 bg-light rounded-3">
            <h1 className="header">
              Welcome To Translate App Conversation: {conversationId} &nbsp;

              {!convoStarted &&
                <Button variant="primary" type="submit" onClick={e => initSpeechCall(e)}>
                  Start Conversation
                </Button>
              }
            </h1>
          </Container></Col>
      </Row>

      {convoStarted &&
        <Row>
          <Col xs='12' md='6'>

          <div className='container'>
              <div className='row'>
            <Form className='col-10'>
              <Form.Group className="mb-3" controlId="formsEngText" >
                <Form.Label>English Text</Form.Label>
                <Form.Control type="text" value={textToSendLan1} onChange={e => setTextToSendLan1(e.target.value)} placeholder="Chat" />
                <Form.Text className="text-muted">
                  Enter text to translate above
                </Form.Text>
              </Form.Group>
            </Form>
            <div className='col-2'><br></br><br></br>
              <Button variant="primary" type="submit" onClick={e => sendMessage(e, 1)}>
                Submit
              </Button>
              </div>
              </div>
              <Button  className="btn-lg w-75" variant="info" onTouchStart={e => speakAudio(e, 1)} onTouchEnd={e => speakAudio(e, 1)} disabled={!isEnAudioEnabled}>
                &nbsp;&nbsp;&nbsp;Speak English (hold)&nbsp;&nbsp;&nbsp;
              </Button>&nbsp;&nbsp;&nbsp;

              {isMic1Enabled && <Icon.Mic color='red' size="2em"></Icon.Mic>}
            </div>
          </Col>
          <Col xs='12' md='6'>
            <div className='container'>
              <div className='row'>
                <Form className='col-10'>
                  <Form.Group className="mb-3" controlId="formsChinaText" >
                    <Form.Label>中文文本</Form.Label>
                    <Form.Control type="text" value={textToSendLan2} onChange={e => setTextToSendLan2(e.target.value)} placeholder="聊天" />
                    <Form.Text className="text-muted">
                      输入要翻译的文本
                    </Form.Text>
                  </Form.Group>
                </Form>
                <div className='col-2'><br></br><br></br>
                  <div className='align-middle'><Button variant="primary w-100" type="submit" onClick={e => sendMessage(e, 2)}>
                  提交
                </Button></div></div>
              </div>
              <Button className="btn-lg w-75" variant="info" onTouchStart={e => speakAudio(e, 2)} onTouchEnd={e => speakAudio(e, 2)} disabled={!isCnAudioEnabled}>
                &nbsp;&nbsp;&nbsp;说中文&nbsp;&nbsp;&nbsp;
              </Button>&nbsp;&nbsp;&nbsp;

              {isMic2Enabled && <Icon.Mic color='red' size="2em"></Icon.Mic>}
            </div>
          </Col>
        </Row>
      }

      {convoStarted &&
        <Row>
          <Col>
            <Container>

              {convos.map(row => {
                return (
                  <Row className='mt-2' key={row.time.toString()} >
                    <Col md="auto"><img src={(row.place == 1) ? '/img/icons8-usa-96.png' : '/img/icons8-china-96.png'} />{/* <Placeholder style={{ width: '50px', height: '50px', background: (row.place == 1) ? 'blue' : 'red' }} /> */}</Col>
                    <Col>
                      <div style={{ background: (row.place == 1) ? '#EBFCFC' : '#FCEBEB' }} className={(row.state === 1) ? "border-3 border p-2 border-success" : (row.state === 2) ? "border-3 border p-2 border-warning" : "border-3 border p-2"}>
                        <Container>
                          <Row>
                            <Col lg="10">

                              <strong className="primary-font">{(row.place == 1) ? 'English' : 'China'} - {row.language}</strong> <small className="pull-right text-muted">
                                <Icon.Clock></Icon.Clock>{Math.abs((new Date().getTime() - row.time.getTime()) / 1000)}</small>

                            </Col>
                            <Col lg="2">
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonGroup aria-label="First group">
                                  <Button variant="success" onClick={e => adjustRow(e, row, 1)}><Icon.EmojiSmile></Icon.EmojiSmile></Button>{' '}
                                  <Button variant="warning" onClick={e => adjustRow(e, row, 2)}><Icon.QuestionCircle></Icon.QuestionCircle></Button>{' '}
                                  <Button variant="danger" onClick={e => adjustRow(e, row, 3)}><Icon.Trash></Icon.Trash></Button>{' '}
                                </ButtonGroup>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col> <p>
                              {row.message}
                            </p>
                              <p>
                                {row.translatemessage}
                              </p></Col>
                          </Row>
                        </Container>
                      </div>

                    </Col>
                  </Row>
                );
              })}
              {/* <Row className='mt-2'>
            <Col md="auto"><Placeholder style={{ width: '50px', height: '50px' }} /></Col>
            <Col>
              <div className="bg-light border">
                <div className="header">
                  <strong className="primary-font">Second Person</strong> <small className="pull-right text-muted">
                    <span className="glyphicon glyphicon-time"></span>12 mins ago</small>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curar bibe0m oe
                  dolor, quis ullamcorper ligula sodales.
                </p>
              </div></Col>
          </Row> */}

            </Container>

          </Col>
        </Row>
      }
    </Container>
    /*<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Static Web App: React App with Azure Function API
        </p>
        <form id="form1" className="App-form" onSubmit={e => getDataFromApi(e)}>
          <div>
            <input 
              type="text" 
              id="name" 
              className="App-input" 
              placeholder="Name" 
              value={name} 
              onChange={e=>setName(e.target.value)} />
            <Button type="submit" className="App-button">Submit 2</Button>
          </div>
        </form>
        <div><h5>Message: {message} </h5></div>
      </header>
    </div>*/
  );
}

export default Home;