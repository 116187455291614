import React from 'react';

export class ConvoEntry {

    //field 
    message: string;
    translatemessage: string;
    language: string;
    sessionid: string = "";
    place: number;
    state: number = 0;
    time: Date;
  
    //constructor 
    constructor(message: string, translatemessage: string, language: string, place: number, time: Date) {
      this.message = message;
      this.translatemessage = translatemessage;
      this.language = language;
      this.place = place;
      this.time = time;
  
    }
  
    //function 
    disp(): void {
      console.log("Message is  :   " + this.message)
    }
  }